import type { FC } from "react";
import { useState, useCallback, useMemo, useEffect } from "react";
import { PageLayout } from "../../components";
import { useTranslation } from "react-i18next";
import { TabButton } from "../../components/atoms/TabButton";
import { Button, Dropdown, Checkbox, Label, Tooltip } from "flowbite-react";
import { RoutePlanner } from "./RoutePlanner";
import { OrderPlanner } from "./OrderPlanner";
import { useDrivers, useOrders } from "../../hooks";
import { useWorkspace } from "../../context/WorkspaceContext";
import { Order } from "../../models";
import { IOrder, IRouteItem } from "../../types";
import { DialogNotify } from "../../components/organisms/DialogNotify";
import { HiCheck, HiOutlineFilter, HiCalendar, HiInformationCircle, HiOutlineTag } from "react-icons/hi";
import { LGBDatepicker } from "../../components";
import { rescheduleRouteItem } from "../../api/client";
import { useUserSession } from "../../context/UserContext";
import moment from "moment";
import { OrderStatus } from "../../types/order/IOrder";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import OrderSuccessPopup from "../../components/molecules/OrderSuccessPopup";

type OrderPlannerState = {
  hasChanges: boolean;
  changesCount: number;
  pendingChanges: { orderId: string; driverId: string }[];
  localOrders: IOrder[];
};

// Statuses to show in the filter
const FILTER_STATUSES = [OrderStatus.New, OrderStatus.Scheduled];

export const PlannerPage: FC = function () {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  
  // Hent tab, dato og sjåfør fra URL-parametere
  const tabFromUrl = searchParams.get('tab') as "order" | "route" | null;
  const dateFromUrl = searchParams.get('date');
  const driverFromUrl = searchParams.get('driver');
  const showSuccessPopupParam = searchParams.get('showSuccessPopup');
  
  const [showSuccessPopup, setShowSuccessPopup] = useState(showSuccessPopupParam === 'true');
  const [activeTab, setActiveTab] = useState<"order" | "route">(tabFromUrl || "order");
  const { t } = useTranslation(["common", "planner", "stats"]);
  const { activeWorkspace } = useWorkspace();
  const { userSession } = useUserSession();
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [shouldReset, setShouldReset] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState<string>(driverFromUrl || "");
  const [selectedDate, setSelectedDate] = useState<Date>(dateFromUrl ? new Date(dateFromUrl) : new Date());
  const [selectedStatuses, setSelectedStatuses] = useState<OrderStatus[]>([OrderStatus.New]);
  const [plannerState, setPlannerState] = useState<OrderPlannerState>({
    hasChanges: false,
    changesCount: 0,
    pendingChanges: [],
    localOrders: []
  });
  const [routePlannerState, setRoutePlannerState] = useState<{
    hasChanges: boolean;
    changesCount: number;
    routeStops: (IRouteItem & { isAvailable: boolean })[];
    hasValidationErrors: boolean;
  }>({
    hasChanges: false,
    changesCount: 0,
    routeStops: [],
    hasValidationErrors: false
  });

  // Memoize workspace ID to prevent unnecessary query changes
  const workspaceId = useMemo(() => activeWorkspace?.workspaceId ?? "", [activeWorkspace?.workspaceId]);

  const orderQuery = useOrders(workspaceId, []);
  const driverQuery = useDrivers(workspaceId);
  
  // Memoize data transformations
  const orders = useMemo(() => orderQuery.data ?? [], [orderQuery.data]);
  const drivers = useMemo(() => driverQuery.data ?? [], [driverQuery.data]);

  // Oppdater URL når tab, dato eller sjåfør endres
  useEffect(() => {
    const params = new URLSearchParams(searchParams);
    
    // Oppdater tab i URL
    params.set('tab', activeTab);
    
    // Oppdater dato i URL
    if (selectedDate) {
      params.set('date', moment(selectedDate).format('YYYY-MM-DD'));
    } else {
      params.delete('date');
    }
    
    // Oppdater sjåfør i URL hvis vi er på route-tab
    if (activeTab === 'route' && selectedDriver) {
      params.set('driver', selectedDriver);
    } else {
      params.delete('driver');
    }
    
    // Sett nye URL-parametere
    setSearchParams(params, { replace: true });
  }, [activeTab, selectedDate, selectedDriver, searchParams, setSearchParams]);

  const handleStatusChange = (status: OrderStatus) => {
    setSelectedStatuses(prev => {
      if (prev.includes(status)) {
        // Allow removing any status, even if it would leave no statuses selected
        return prev.filter(s => s !== status);
      }
      return [...prev, status];
    });
  };

  // Get status color
  const getStatusColor = (status: OrderStatus) => {
    switch (status) {
      case OrderStatus.New:
        return "bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300";
      case OrderStatus.Scheduled:
        return "bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-300";
      case OrderStatus.InProgress:
        return "bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300";
      case OrderStatus.Completed:
        return "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300";
      case OrderStatus.Cancelled:
        return "bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300";
      default:
        return "bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-gray-300";
    }
  };

  const handleSave = useCallback(async () => {
    if (!userSession?.token || !activeWorkspace?.workspaceId) return;

    if (activeTab === "order") {
      if (!plannerState.hasChanges) return;
      
      setIsUpdating(true);

      try {
        // Get original orders from query data
        const originalOrders = orderQuery.data ?? [];
        
        // Process each change
        await Promise.all(
          plannerState.pendingChanges.map(async (change) => {
            const originalOrder = originalOrders.find(order => order.id === change.orderId);
            if (!originalOrder) return;
            
            try {
              await Order.update(originalOrder as Order, { driverId: change.driverId });
            } catch (error) {
              console.error(`Failed to update order ${change.orderId}:`, error);
            }
          })
        );

        // Refresh the orders query to get the latest data
        await orderQuery.refetch();
        setShowSuccessDialog(true);
        // Reset the state after successful save
        setShouldReset(true);
        setPlannerState({
          hasChanges: false,
          changesCount: 0,
          pendingChanges: [],
          localOrders: []
        });
      } catch (error) {
        console.error("Error updating orders:", error);
      } finally {
        setIsUpdating(false);
      }
    } else {
      // Handle route planner save
      if (!routePlannerState.hasChanges) return;
      
      setIsUpdating(true);
      try {
        // Update each route in the scheduled column
        await Promise.all(
          routePlannerState.routeStops.map(async (stop, index) => {
            try {
              await rescheduleRouteItem(
                userSession.token.accessToken,
                activeWorkspace.workspaceId,
                stop.routeId ?? "",
                {
                  status: "Scheduled",
                  routeDate: moment(selectedDate).format('YYYY-MM-DD'),
                  routeStopNumber: index
                }
              );
            } catch (error) {
              console.error(`Failed to update route ${stop.routeId}:`, error);
            }
          })
        );

        setShowSuccessDialog(true);
        setShouldReset(true);
        setRoutePlannerState({
          hasChanges: false,
          changesCount: 0,
          routeStops: [],
          hasValidationErrors: false
        });
      } catch (error) {
        console.error("Error updating routes:", error);
      } finally {
        setIsUpdating(false);
      }
    }
  }, [activeTab, plannerState, routePlannerState, orderQuery, userSession?.token, activeWorkspace?.workspaceId, selectedDate]);

  const handleDialogClose = useCallback(() => {
    setShowSuccessDialog(false);
  }, []);

  const handleDiscard = useCallback(() => {
    setShouldReset(true);
    if (activeTab === "order") {
      setPlannerState({
        hasChanges: false,
        changesCount: 0,
        pendingChanges: [],
        localOrders: []
      });
    } else {
      setRoutePlannerState({
        hasChanges: false,
        changesCount: 0,
        routeStops: [],
        hasValidationErrors: false
      });
    }
  }, [activeTab]);

  // Reset the shouldReset flag after it's been used
  useEffect(() => {
    if (shouldReset) {
      setShouldReset(false);
    }
  }, [shouldReset]);

  // Memoize the OrderPlanner component
  const orderPlanner = useMemo(() => (
    <OrderPlanner 
      drivers={drivers} 
      orders={orders}
      onChange={setPlannerState}
      reset={shouldReset}
      selectedDate={selectedDate}
      selectedStatuses={selectedStatuses}
      selectedDriver={selectedDriver}
      onSelectDriver={setSelectedDriver}
    />
  ), [drivers, orders, shouldReset, selectedDate, selectedStatuses, selectedDriver, setSelectedDriver]);

  // Memoize the dialog component
  const dialog = useMemo(() => (
    <DialogNotify
      show={showSuccessDialog}
      isLoading={false}
      title={t("planner:changes_saved_title")}
      text={t("planner:changes_saved_message")}
      icon={<HiCheck className="h-6 w-6 text-green-600" />}
      toggleModal={handleDialogClose}
      confirmButton={handleDialogClose}
      buttonConfirmText="OK"
      buttonConfirmColor="mainGreen"
    />
  ), [showSuccessDialog, handleDialogClose, t]);

  // Memoize the action buttons
  const actionButtons = useMemo(() => {
    const hasChanges = activeTab === "order" ? plannerState.hasChanges : routePlannerState.hasChanges;
    const changesCount = activeTab === "order" ? plannerState.changesCount : routePlannerState.changesCount;
    const hasValidationErrors = activeTab === "route" && routePlannerState.hasValidationErrors;

    return (
      <div className="flex items-center gap-4">
        <span 
          onClick={hasChanges ? handleDiscard : undefined}
          className={`${hasChanges ? 'cursor-pointer hover:underline' : 'cursor-not-allowed opacity-50'} text-red-600 hover:text-red-700 visited:text-red-800 dark:text-red-400 dark:hover:text-red-300 dark:visited:text-red-300`}
        >
          {t("planner:discard_changes")}
        </span>
        <Button
          color="mainGreen"
          disabled={!hasChanges || isUpdating || hasValidationErrors}
          onClick={handleSave}
        >
          {isUpdating ? t("planner:saving") : `${t("planner:save_changes")} (${changesCount})`}
        </Button>
      </div>
    );
  }, [activeTab, plannerState.hasChanges, plannerState.changesCount, routePlannerState.hasChanges, routePlannerState.changesCount, routePlannerState.hasValidationErrors, isUpdating, handleSave, handleDiscard, t]);

  // Add scroll event listener to make action buttons sticky
  useEffect(() => {
    const handleScroll = () => {
      const actionButtonsContainer = document.getElementById('action-buttons-container');
      const stickyContainer = document.getElementById('sticky-action-buttons');
      
      if (actionButtonsContainer && stickyContainer) {
        const rect = actionButtonsContainer.getBoundingClientRect();
        
        if (rect.top < 0) {
          if (stickyContainer.classList.contains('hidden')) {
            stickyContainer.classList.remove('hidden');
            stickyContainer.classList.add('block');
          }
        } else {
          if (!stickyContainer.classList.contains('hidden')) {
            stickyContainer.classList.add('hidden');
            stickyContainer.classList.remove('block');
          }
        }
      }
    };
    
    // Initial check and setup
    setTimeout(() => {
      handleScroll();
      // Force a check after components have fully rendered
      window.dispatchEvent(new Event('scroll'));
    }, 300);
    
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Håndter lukking av suksess-popupen
  const handleCloseSuccessPopup = () => {
    setShowSuccessPopup(false);
    // Fjern showSuccessPopup fra URL
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('showSuccessPopup');
    setSearchParams(newSearchParams);
  };

  // Håndter planlegging av stopp
  const handlePlanStops = () => {
    setShowSuccessPopup(false);
    // Fjern showSuccessPopup fra URL
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('showSuccessPopup');
    setSearchParams(newSearchParams);
    // Sett aktivt tab til "route" (dette er alt som trengs, siden vi allerede er på planner-siden)
    setActiveTab("route");
  };

  // Håndter opprettelse av ny ordre
  const handleCreateNewOrder = () => {
    setShowSuccessPopup(false);
    // Naviger til opprett ordre-siden
    navigate('/orders/new');
  };

  return (
    <>
      {/* Sticky action buttons that appear when scrolling - outside PageLayout to be above navbar */}
      <div 
        id="sticky-action-buttons" 
        className="hidden fixed top-0 left-0 right-0 z-[9999] bg-white dark:bg-gray-800 shadow-md border-b border-gray-200 dark:border-gray-700 w-full"
        style={{ transition: 'all 0.3s ease' }}
      >
        <div className="w-full py-7 px-5 flex justify-end items-center">
          {actionButtons}
        </div>
      </div>
      
      <PageLayout>
        <div className="space-y-4">
          {/* Header section */}
          <div className="px-4">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
              <div>
                <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
                  {t("planner:title")}
                </h1>
                <p className="text-lgb-grey-600 text-base pt-2 dark:text-lgb-grey-200">
                  {t("planner:description")}
                </p>
              </div>
              
              {/* Action buttons - hidden on mobile, shown on desktop */}
              <div id="action-buttons-container" className="hidden sm:block">
                {actionButtons}
              </div>
            </div>
          </div>

          {/* Mobile layout - restructured for mobile */}
          <div className="md:hidden">
            {/* Tabs at the top */}
            <div className="border-b border-gray-200 dark:border-gray-700 px-4">
              <ul className="flex flex-wrap -mb-px text-sm font-medium text-center w-full">
                <TabButton
                  label={t("planner:order_tab")}
                  isSelected={activeTab === "order"}
                  onClick={() => setActiveTab("order")}
                  className="flex-1"
                />
                <TabButton
                  label={t("planner:route_tab")}
                  isSelected={activeTab === "route"}
                  onClick={() => setActiveTab("route")}
                  className="flex-1"
                />
              </ul>
            </div>
            
            {/* Filters below tabs */}
            <div className="px-4 py-3 border-b border-gray-200 dark:border-gray-700">
              <div className="flex items-center justify-between gap-2">
                {/* Status filter dropdown */}
                {activeTab === "order" && (
                  <Dropdown
                    label={
                      <div className="flex items-center gap-1.5 text-sm">
                        <HiOutlineTag className="h-4 w-4 mr-1" />
                        <span>{t("common:status")}</span>
                        {selectedStatuses.length > 0 && (
                          <span className="ml-1.5 bg-lgb-blue-300 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                            {selectedStatuses.length}
                          </span>
                        )}
                      </div>
                    }
                    color="light"
                    size="sm"
                    dismissOnClick={false}
                  >
                    <div className="p-3 space-y-2">
                      <div className="flex items-center gap-2 mb-2">
                        <div className="text-sm font-medium text-gray-900 dark:text-white">
                          {t("planner:filter_by_status")}:
                        </div>
                        <Tooltip className="w-[300px]" content={t("planner:only_new_orders_movable")}>
                          <HiInformationCircle className="h-4 w-4 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 cursor-help" />
                        </Tooltip>
                      </div>
                      {FILTER_STATUSES.map((status) => (
                        <div key={status} className="flex items-center gap-2">
                          <Checkbox
                            id={`status-${status}`}
                            color="primary"
                            checked={selectedStatuses.includes(status)}
                            onChange={() => handleStatusChange(status)}
                            className="focus:ring-0 focus:ring-offset-0 checked:bg-lgb-blue-300 border-gray-300"
                          />
                          <Label 
                            htmlFor={`status-${status}`} 
                            className="text-sm"
                          >
                            {t(`stats:orderStatus.${status === OrderStatus.InProgress ? 'in_progress' : status.toLowerCase()}`)}
                          </Label>
                          <span className={`ml-auto text-xs px-1.5 py-0.5 rounded-full ${getStatusColor(status)}`}>
                            {plannerState.localOrders.filter(o => o.status === status).length}
                          </span>
                        </div>
                      ))}
                    </div>
                  </Dropdown>
                )}
                
                {/* Date picker */}
                <div className="w-40">
                  <LGBDatepicker
                    select={(date: Date) => setSelectedDate(date)}
                    selectedDate={selectedDate || undefined}
                    showError={false}
                  />
                </div>
              </div>
            </div>
            
            {/* Action buttons for mobile - fixed at bottom */}
            <div className="fixed bottom-0 left-0 right-0 bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700 p-4 z-50">
              {actionButtons}
            </div>
            
            {/* Content with padding at bottom to account for fixed action buttons */}
            <div className="px-4 pb-20 pt-6">
              {activeTab === "order" ? (
                orderPlanner
              ) : (
                <RoutePlanner
                  selectedDate={selectedDate}
                  selectedDriver={selectedDriver}
                  onSelectDriver={setSelectedDriver}
                  drivers={drivers}
                  onChange={setRoutePlannerState}
                  reset={shouldReset}
                />
              )}
            </div>
          </div>

          {/* Desktop layout - original layout preserved */}
          <div className="hidden md:block">
            {/* Tabs and Filters */}
            <div className="border-b border-gray-200 dark:border-gray-700">
              <div className="px-4">
                <div className="flex justify-between items-center">
                  <ul className="flex flex-wrap -mb-px text-sm font-medium text-center">
                    <TabButton
                      label={t("planner:order_tab")}
                      isSelected={activeTab === "order"}
                      onClick={() => setActiveTab("order")}
                    />
                    <TabButton
                      label={t("planner:route_tab")}
                      isSelected={activeTab === "route"}
                      onClick={() => setActiveTab("route")}
                    />
                  </ul>
                  
                  {/* Filters */}
                  <div className="flex items-center gap-3">
                    {activeTab === "order" && (
                      <div className="flex items-center gap-3">
                        {/* Status filter dropdown */}
                        <Dropdown
                          label={
                            <div className="flex items-center gap-1.5 text-sm">
                              <HiOutlineTag className="h-4 w-4 mr-1" />
                              <span>{t("common:status")}</span>
                              {selectedStatuses.length > 0 && (
                                <span className="ml-1.5 bg-lgb-blue-300 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                                  {selectedStatuses.length}
                                </span>
                              )}
                            </div>
                          }
                          color="light"
                          size="sm"
                          dismissOnClick={false}
                        >
                          <div className="p-3 space-y-2">
                            <div className="flex items-center gap-2 mb-2">
                              <div className="text-sm font-medium text-gray-900 dark:text-white">
                                {t("planner:filter_by_status")}:
                              </div>
                              <Tooltip className="w-[300px]" content={t("planner:only_new_orders_movable")}>
                                <HiInformationCircle className="h-4 w-4 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 cursor-help" />
                              </Tooltip>
                            </div>
                            {FILTER_STATUSES.map((status) => (
                              <div key={status} className="flex items-center gap-2">
                                <Checkbox
                                  id={`status-${status}`}
                                  color="primary"
                                  checked={selectedStatuses.includes(status)}
                                  onChange={() => handleStatusChange(status)}
                                  className="focus:ring-0 focus:ring-offset-0 checked:bg-lgb-blue-300 border-gray-300"
                                />
                                <Label 
                                  htmlFor={`status-${status}`} 
                                  className="text-sm"
                                >
                                  {t(`stats:orderStatus.${status === OrderStatus.InProgress ? 'in_progress' : status.toLowerCase()}`)}
                                </Label>
                                <span className={`ml-auto text-xs px-1.5 py-0.5 rounded-full ${getStatusColor(status)}`}>
                                  {plannerState.localOrders.filter(o => o.status === status).length}
                                </span>
                              </div>
                            ))}
                          </div>
                        </Dropdown>
                      </div>
                    )}
                    
                    {/* Date picker */}
                    <div className="w-40">
                      <LGBDatepicker
                        select={(date: Date) => setSelectedDate(date)}
                        selectedDate={selectedDate || undefined}
                        showError={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Content - med mer padding på toppen */}
            <div className="px-4 pb-4 pt-6">
              {activeTab === "order" ? (
                orderPlanner
              ) : (
                <RoutePlanner
                  selectedDate={selectedDate}
                  selectedDriver={selectedDriver}
                  onSelectDriver={setSelectedDriver}
                  drivers={drivers}
                  onChange={setRoutePlannerState}
                  reset={shouldReset}
                />
              )}
            </div>
          </div>
        </div>

        {showSuccessPopup && (
          <OrderSuccessPopup
            onClose={handleCloseSuccessPopup}
            onPlanStops={handlePlanStops}
            onCreateNewOrder={handleCreateNewOrder}
          />
        )}
      </PageLayout>
    </>
  );
};