/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, type FC } from "react";
import { IOrder } from "../../../types";
import { useTranslation } from "react-i18next";
import { Spinner, Textarea } from "flowbite-react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { HiCheck, HiOutlineUser, HiOutlineCalendar, HiOutlineScale, HiOutlineCube, HiOutlineArrowUp, HiOutlineArrowDown } from "react-icons/hi";
import { Order } from "../../../models";
import { useWorkspace } from "../../../context/WorkspaceContext";
import { useUserSession } from "../../../context/UserContext";
import { useCache } from "../../../context/CacheContext";
import { IOrderProduct } from "../../../types";
import { createOrder as createOrderApi } from "../../../api";
import { StyledButton } from "../../atoms/Button";

export interface OrderSummaryProp {
  order: IOrder;
  close: () => void;
}

export const OrderSummary: FC<OrderSummaryProp> = (props) => {
  const { t } = useTranslation(["orders"]);
  const [comment, setComment] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [create, setCreate] = useState(false);
  const [fullOrder, setFullOrder] = useState(props.order);

  const { activeWorkspace } = useWorkspace();
  const { userSession } = useUserSession();
  const { updateCacheKey } = useCache();
  const navigate = useNavigate();

  useEffect(() => {
    const createOrder = async () => {
      setIsLoading(true);
      await createOrderApi(
        userSession?.token.accessToken ?? "",
        activeWorkspace?.workspaceId ?? "",
        fullOrder,
      ).then((res) => {
        if (res) {
          updateCacheKey();
          
          navigate({
            pathname: "/planner",
            search: createSearchParams({
              tab: "route",
              driver: fullOrder.driverId || "",
              date: fullOrder.route[0].stopDate.toString(),
              showSuccessPopup: "true"
            }).toString()
          });
        } else {
          setIsLoading(false);
        }
      }).catch((error) => {
        console.error("Feil ved opprettelse av ordre:", error);
        setIsLoading(false);
      });
    };
    if (create) {
      createOrder();
    }
  }, [fullOrder, create, navigate, activeWorkspace, userSession, updateCacheKey]);

  const set = (key: string, val: string) => {
    setFullOrder((fullOrder) => ({ ...fullOrder, [key]: val }) as Order);
  };

  const stopTypes = () => {
    const deliveryStops = props.order.cargo.map(
      (x) => x.itinerary?.deliveryStopNumber,
    );
    const pickupStops = props.order.cargo.map(
      (x) => x.itinerary?.pickupStopNumber,
    );

    return {
      deliveryStops: order.route.filter((x) =>
        deliveryStops.includes(x.stopNumber),
      ),
      pickupStops: order.route.filter((x) =>
        pickupStops.includes(x.stopNumber),
      ),
    };
  };

  const order = props.order;
  const customerName = order.customer.isCompany
    ? order.customer.name
    : order.customer.firstName + " " + order.customer.lastName;

  const calculateTotalVolume = (products: IOrderProduct[]) => {
    const total = products.reduce(
      (partialSum, item) => {
        // Sikre at alle verdier er tall
        const length = typeof item.length === 'number' ? item.length : 0;
        const height = typeof item.height === 'number' ? item.height : 0;
        const width = typeof item.width === 'number' ? item.width : 0;
        const quantity = typeof item.quantity === 'number' ? item.quantity : 1;
        
        // Beregn volum for dette produktet (konverter cm til m)
        const itemVolume = (length / 100) * (height / 100) * (width / 100);
        
        // Legg til i totalen, multiplisert med antall
        return partialSum + (itemVolume > 0 ? itemVolume * quantity : 0);
      },
      0
    );
    
    return total > 0 ? total.toFixed(2) : "0";
  };

  const calculateTotalWeight = (products: IOrderProduct[]) => {
    const total = products.reduce(
      (partialSum, item) => {
        // Sikre at alle verdier er tall
        const weight = typeof item.weight === 'number' ? item.weight : 0;
        const quantity = typeof item.quantity === 'number' ? item.quantity : 1;
        
        // Legg til i totalen, multiplisert med antall
        return partialSum + (weight > 0 ? weight * quantity : 0);
      },
      0
    );
    
    return total > 0 ? total.toFixed(1) : "0";
  };

  const validateAndCreate = async () => {
    set("comment", comment);
    set("workspaceId", activeWorkspace?.workspaceId || "");
    setCreate(true);
  };

  return (
    <div className="flex flex-col">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        {/* Venstre kolonne - Dato, kunde og rute */}
        <div className="space-y-4">
          {/* Dato og kunde info */}
          <div className="bg-gray-50 dark:bg-gray-700 p-3 rounded-lg">
            <div className="flex flex-wrap items-center mb-2">
              <HiOutlineCalendar size={20} className="text-lgb-blue-400 dark:text-lgb-blue-300 mr-2" />
              <span className="font-medium text-sm text-gray-900 dark:text-white">{t("create.driver_selection.summary.date")}:</span>
              <span className="ml-2 text-sm text-gray-700 dark:text-gray-200">
                {order.route && order.route.length > 0 && order.route[0]?.stopDate ? 
                  (order.route[0].stopDate && typeof order.route[0].stopDate.toDate === 'function' 
                    ? order.route[0].stopDate.toDate().toLocaleDateString() 
                    : new Date(order.route[0].stopDate as any).toLocaleDateString()) 
                  : ""}
              </span>
            </div>
            <div className="flex flex-wrap items-center">
              <HiOutlineUser className="text-lgb-green-400 mr-2 h-5 w-5" />
              <span className="font-medium text-sm text-gray-900 dark:text-white">{t("create.driver_selection.summary.customer")}:</span>
              <span className="ml-2 text-sm text-gray-700 dark:text-gray-200 truncate max-w-full">{customerName}</span>
            </div>
          </div>

          {/* Rute visualisering - forbedret visuell fremstilling */}
          <div className="bg-gray-50 dark:bg-gray-700 p-3 rounded-lg">
            <h3 className="font-medium text-sm mb-2 text-gray-900 dark:text-white">{t("create.route_selection.title")}</h3>
            <div className="relative pl-6">
              {/* Pickup location */}
              <div className="flex items-start mb-3 relative">
                <div className="absolute left-[-24px] top-0 flex flex-col items-center">
                  <div className="h-5 w-5 rounded-full bg-lgb-blue-400 flex items-center justify-center">
                    <HiOutlineArrowUp className="h-3 w-3 text-white" />
                  </div>
                  <div className="h-full w-0.5 bg-lgb-blue-400 opacity-50 absolute top-5"></div>
                </div>
                <div>
                  <div className="font-medium text-sm text-gray-900 dark:text-white flex items-center">
                    <span className="bg-lgb-blue-100 dark:bg-lgb-blue-900/30 text-lgb-blue-700 dark:text-lgb-blue-300 px-2 py-0.5 rounded text-xs mr-2">{t("create.driver_selection.summary.pick_up")}</span>
                  </div>
                  <div className="text-sm text-gray-600 dark:text-gray-300 mt-1 truncate max-w-[90%]">
                    {order.route.at(0)?.location.addressLine}
                  </div>
                </div>
              </div>

              {/* Delivery locations */}
              {stopTypes().deliveryStops.map((r, index) => (
                <div key={r?.stopNumber} className="flex items-start relative mb-2">
                  <div className="absolute left-[-24px] top-0 flex flex-col items-center">
                    <div className="h-5 w-5 rounded-full bg-lgb-green-400 flex items-center justify-center">
                      <HiOutlineArrowDown className="h-3 w-3 text-white" />
                    </div>
                    {index < stopTypes().deliveryStops.length - 1 && (
                      <div className="h-full w-0.5 bg-lgb-green-400 opacity-50 absolute top-5"></div>
                    )}
                  </div>
                  <div>
                    <div className="font-medium text-sm text-gray-900 dark:text-white flex items-center">
                      <span className="bg-lgb-green-100 dark:bg-lgb-green-900/30 text-lgb-green-700 dark:text-lgb-green-300 px-2 py-0.5 rounded text-xs mr-2">{t("create.driver_selection.summary.delivery")}</span>
                    </div>
                    <div className="text-sm text-gray-600 dark:text-gray-300 mt-1 truncate max-w-[90%]">
                      {r.location.addressLine}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Høyre kolonne - Vekt, volum og kommentar */}
        <div className="space-y-4">
          {/* Vekt og volum - tilpasset for mobil med større ikoner */}
          <div className="grid grid-cols-2 gap-3">
            <div className="bg-gray-50 dark:bg-gray-700 p-3 rounded-lg">
              <div className="flex items-center">
                <HiOutlineScale className="text-lgb-blue-400 dark:text-lgb-blue-300 mr-2 h-5 w-5" />
                <span className="font-medium text-sm text-gray-900 dark:text-white">{t("create.driver_selection.summary.total_weight")}</span>
              </div>
              <div className="text-lg font-bold mt-2 text-gray-900 dark:text-white">{calculateTotalWeight(order.cargo)} kg</div>
            </div>
            <div className="bg-gray-50 dark:bg-gray-700 p-3 rounded-lg">
              <div className="flex items-center">
                <HiOutlineCube className="text-lgb-blue-400 dark:text-lgb-blue-300 mr-2 h-5 w-5" />
                <span className="font-medium text-sm text-gray-900 dark:text-white">{t("create.driver_selection.summary.total_volume")}</span>
              </div>
              <div className="text-lg font-bold mt-2 text-gray-900 dark:text-white">
                {calculateTotalVolume(order.cargo)} m<sup>3</sup>
              </div>
            </div>
          </div>

          {/* Kommentar - redusert høyde */}
          <div className="bg-gray-50 dark:bg-gray-700 p-3 rounded-lg">
            <p className="font-medium text-sm mb-2 text-gray-900 dark:text-white">{t("create.comment_title")}</p>
            <Textarea
              style={{ height: "120px" }}
              className="bg-white dark:bg-gray-600 dark:text-white text-gray-900 box-border text-sm w-full border-gray-300 dark:border-gray-600 focus:ring-lgb-blue-300 focus:border-lgb-blue-300"
              id="comment"
              name="comment"
              placeholder={t("create.comment_ph")}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
        </div>
      </div>

      {/* Knapper - fast posisjon i bunnen */}
      <div className="flex justify-end gap-3 pt-3 border-t border-gray-200 dark:border-gray-600 bg-white dark:bg-lgb-dark-background">
        <StyledButton
          variant="secondary"
          onClick={() => props.close()}
          size="md"
          className="px-5 py-2.5"
        >
          {t("common:close")}
        </StyledButton>
        
        {!loading ? (
          <StyledButton
            variant="success"
            onClick={validateAndCreate}
            icon={<HiCheck />}
            size="md"
            className="px-5 py-2.5"
          >
            {t("create.create_order_button")}
          </StyledButton>
        ) : (
          <StyledButton
            variant="success"
            onClick={() => {}}
            disabled={true}
            size="md"
            className="px-5 py-2.5"
          >
            <Spinner size="sm" />
          </StyledButton>
        )}
      </div>
    </div>
  );
};
