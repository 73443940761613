import React, { useEffect, useState, useMemo, useCallback } from "react";
import { PageLayout } from "../components";
import { useWorkspace } from "../context/WorkspaceContext";
import { useTranslation } from "react-i18next";
import { Card, Badge, Avatar } from "flowbite-react";
import { useOrders, useDrivers } from "../hooks";
import { Order, Driver } from "../models";
import { OrderStatus } from "../types/order/IOrder";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useNavigate } from "react-router-dom";
import GreetingsWidget from "../components/molecules/Widgets/GreetingsWidget";
import { HiArrowRight } from "react-icons/hi";

// Registrer nødvendige ChartJS-komponenter
ChartJS.register(ArcElement, Tooltip, Legend);

// Pulserende sirkel-komponent
const PulsingDot: React.FC = React.memo(() => (
  <div className="relative inline-flex mr-2">
    <div className="w-3 h-3 bg-green-500 rounded-full"></div>
    <div className="absolute inset-0 rounded-full bg-green-400 animate-ping opacity-75" style={{ animationDuration: '3s' }}></div>
  </div>
));

// Komponent for tom tilstand
interface EmptyStateProps {
  icon: React.ReactNode;
  message: string;
}

const EmptyState: React.FC<EmptyStateProps> = React.memo(({ icon, message }) => (
  <div className="flex flex-col items-center justify-center h-full">
    {icon}
    <p className="text-gray-500 dark:text-gray-400">{message}</p>
  </div>
));

// Komponent for fremdriftslinje med pulserende bakgrunn
interface ProgressBarProps {
  percentage: number;
  hasOrders: boolean;
  completedText: string;
}

const ProgressBar: React.FC<ProgressBarProps> = React.memo(({ percentage, hasOrders, completedText }) => (
  <div className="relative">
    <div className={`overflow-hidden h-6 text-xs flex rounded-full bg-lgb-blue-100 dark:bg-gray-700 relative`}>
      <div 
        style={{ 
          width: `${percentage > 0 ? percentage : 3}%`,
          background: 'linear-gradient(90deg, #7214FF 0%, #C7A1FF 100%)'
        }} 
        className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center rounded-full transition-all duration-500 z-10 ${hasOrders ? 'progress-bar-animated' : ''} ${percentage === 0 && hasOrders ? 'progress-bar-pulse' : ''}`}
      >
        {percentage > 0 && (
          <span className="text-xs font-semibold z-10 px-2">{percentage}% <span className="hidden md:inline">{completedText}</span></span>
        )}
      </div>
      {percentage === 0 && (
        <span className="text-xs font-semibold text-gray-600 dark:text-gray-300 mx-auto my-auto">{percentage}% <span className="hidden md:inline">{completedText}</span></span>
      )}
    </div>
  </div>
));

// Komponent for sjåførliste
interface DriverListProps {
  drivers: any[];
  activeText: string;
  finishedText: string;
  notStartedText: string;
  ordersText: string;
}

const DriverList: React.FC<DriverListProps> = React.memo(({ drivers, activeText, finishedText, notStartedText, ordersText }) => (
  <div className="space-y-4">
    {drivers.map(driver => (
      <div key={driver.id} className="flex items-center justify-between">
        <div className="flex items-center">
          <div className="mr-3">
            <Avatar rounded size="md" placeholderInitials={driver.name.charAt(0).toUpperCase()} />
          </div>
          <div>
            <p className="font-medium text-gray-900 dark:text-white">{driver.name}</p>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              {driver.orderCount} {ordersText}
            </p>
          </div>
        </div>
        <Badge color={driver.hasInProgressOrders ? "indigo" : driver.hasCompletedOrders && !driver.hasInProgressOrders && !driver.hasOtherOrders ? "success" : "gray"}>
          {driver.hasInProgressOrders 
            ? activeText 
            : driver.hasCompletedOrders && !driver.hasInProgressOrders && !driver.hasOtherOrders 
              ? finishedText 
              : notStartedText}
        </Badge>
      </div>
    ))}
  </div>
));

// Ikoner for tomme tilstander
const ChartIcon = React.memo(() => (
  <svg className="w-12 h-12 text-gray-400 mb-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
  </svg>
));

const DriversIcon = React.memo(() => (
  <svg className="w-12 h-12 text-gray-400 mb-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path>
  </svg>
));

const IncomeIcon = React.memo(() => (
  <svg className="w-16 h-16 text-gray-400 mb-3 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
  </svg>
));

// Komponent for skeleton loading
const SkeletonLoader: React.FC = React.memo(() => (
  <PageLayout>
    {/* Tynn fremdriftslinje øverst */}
    <div className="mb-4">
      <div className="overflow-hidden h-6 text-xs flex rounded-full bg-gray-200 dark:bg-gray-700 animate-pulse"></div>
    </div>
    
    {/* Ny layout for GreetingsWidget og KPI-kort */}
    <div className="flex flex-col md:flex-row gap-4 mb-4">
      {/* GreetingsWidget skeleton (1/3 bredde) */}
      <div className="md:w-1/3 h-[220px] bg-gray-200 dark:bg-gray-700 rounded-lg animate-pulse"></div>
      
      {/* KPI-kort skeleton (2/3 bredde) */}
      <div className="md:w-2/3 grid grid-cols-1 sm:grid-cols-2 gap-4">
        {[...Array(4)].map((_, index) => (
          <Card key={index} className="dark:bg-[#0b0f26] sm:py-2 py-1">
            <div className="flex justify-between items-center">
              <div className="h-5 bg-gray-200 dark:bg-gray-700 rounded w-24 animate-pulse"></div>
              <div className="h-8 bg-gray-200 dark:bg-gray-700 rounded w-12 animate-pulse"></div>
            </div>
          </Card>
        ))}
      </div>
    </div>
    
    {/* KPI-seksjonen skeleton */}
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
      {[...Array(2)].map((_, index) => (
        <Card key={index} className="dark:bg-[#0b0f26]">
          <div className="h-5 bg-gray-200 dark:bg-gray-700 rounded w-32 mb-4 animate-pulse"></div>
          <div className="h-64 bg-gray-200 dark:bg-gray-700 rounded animate-pulse"></div>
        </Card>
      ))}
    </div>
  </PageLayout>
));

// KPI-kort komponent
interface KpiCardProps {
  title: string;
  count: number;
  color: string;
  onClick?: () => void;
}

const KpiCard: React.FC<KpiCardProps> = React.memo(({ title, count, color, onClick }) => (
  <Card 
    className={`dark:bg-[#0b0f26] sm:py-2 py-1 h-full ${onClick ? 'cursor-pointer hover:shadow-lg hover:scale-[1.02] transition-all duration-200' : ''}`}
    onClick={onClick}
  >
    <div className="flex justify-between items-center">
      <div className="flex items-center">
        <h5 className="text-sm sm:text-sm md:text-md font-bold tracking-tight text-gray-900 dark:text-white">
          {title}
        </h5>
        {onClick && (
          <HiArrowRight className="ml-1 sm:ml-2 text-gray-500 dark:text-gray-400 opacity-70 transition-opacity hover:opacity-100 w-3 h-3 sm:w-4 sm:h-4 flex-shrink-0" />
        )}
      </div>
      <div className={`text-xl sm:text-2xl md:text-3xl font-bold ${count === 0 ? 'text-gray-400 dark:text-gray-500' : color}`}>
        {count}
      </div>
    </div>
  </Card>
));

const RealtimeDashboard: React.FC = () => {
  const { t } = useTranslation(["stats", "common"]);
  const { activeWorkspace } = useWorkspace();
  const navigate = useNavigate();
  
  // Legg til CSS for animasjon
  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
      @keyframes progressShimmer {
        0% {
          background-position: 200% 0;
        }
        100% {
          background-position: 0% 0;
        }
      }
      .progress-bar-animated {
        background-size: 200% 100% !important;
        background-image: linear-gradient(90deg, 
          #7214FF 0%, 
          #C7A1FF 45%, 
          #E9DEFF 50%, 
          #C7A1FF 55%, 
          #7214FF 100%) !important;
        animation-name: progressShimmer;
        animation-duration: 3s;
        animation-timing-function: ease-in-out;
        animation-delay: 7s;
        animation-iteration-count: infinite;
        animation-direction: normal;
        animation-fill-mode: none;
      }
      
      @media (prefers-color-scheme: dark) {
        .progress-bar-animated {
          background-image: linear-gradient(90deg, 
            #5A10CC 0%, 
            #9B7AD6 45%, 
            #C7A1FF 50%, 
            #9B7AD6 55%, 
            #5A10CC 100%) !important;
        }
      }
      
      .progress-bar-pulse {
        position: relative;
        overflow: hidden;
        animation: scale-pulse 4s ease-in-out infinite;
        transform-origin: center;
      }
      
      @keyframes scale-pulse {
        0% {
          transform: scaleY(0.95);
        }
        50% {
          transform: scaleY(1);
        }
        100% {
          transform: scaleY(0.95);
        }
      }
      
      .progress-bar-pulse::after {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.1), transparent);
        animation: shimmer 3s infinite;
      }
      
      @keyframes shimmer {
        0% {
          left: -100%;
        }
        100% {
          left: 100%;
        }
      }
      
      @media (prefers-color-scheme: dark) {
        .progress-bar-pulse::after {
          background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.05), transparent);
        }
      }
    `;
    document.head.appendChild(style);
    
    return () => {
      document.head.removeChild(style);
    };
  }, []);
  
  // State for data
  const [totalOrderValue, setTotalOrderValue] = useState<number>(0);
  const [todaysOrders, setTodaysOrders] = useState<Order[]>([]);
  const [statusCounts, setStatusCounts] = useState<Record<string, number>>({});
  const [completionPercentage, setCompletionPercentage] = useState<number>(0);
  const [activeDrivers, setActiveDrivers] = useState<any[]>([]);
  
  // Hent ordrer
  const orderQuery = useOrders(activeWorkspace?.workspaceId ?? "", []);
  const driverQuery = useDrivers(activeWorkspace?.workspaceId ?? "");
  const orders = orderQuery.data || [];
  const drivers = driverQuery.data || [];
  const isLoading = orderQuery.isLoading || orderQuery.isRefetching || driverQuery.isLoading || driverQuery.isRefetching;
  
  // Nullstill state når workspace endres
  useEffect(() => {
    setTotalOrderValue(0);
    setTodaysOrders([]);
    setStatusCounts({});
    setCompletionPercentage(0);
    setActiveDrivers([]);
  }, [activeWorkspace]);
  
  // Funksjon for å sjekke om en dato er i dag
  const isToday = useCallback((date: Date): boolean => {
    const today = new Date();
    return date.getDate() === today.getDate() &&
           date.getMonth() === today.getMonth() &&
           date.getFullYear() === today.getFullYear();
  }, []);
  
  // Funksjon for å konvertere timestamp til Date
  const getDateFromTimestamp = useCallback((timestamp: any): Date | null => {
    if (!timestamp) return null;
    
    if (timestamp instanceof Date) {
      return timestamp;
    } else if (timestamp.toDate && typeof timestamp.toDate === 'function') {
      return timestamp.toDate();
    } else if (timestamp.seconds && timestamp.nanoseconds) {
      // Forsøk å konvertere fra Firestore Timestamp-lignende objekt
      try {
        // Bruk standard Date-konstruktør for å lage en dato fra sekunder
        return new Date(timestamp.seconds * 1000);
      } catch (error) {
        console.error("Feil ved konvertering av timestamp:", error);
        return null;
      }
    } else if (typeof timestamp === 'string') {
      const date = new Date(timestamp);
      return isNaN(date.getTime()) ? null : date;
    }
    
    return null;
  }, []);
  
  // Oppdater dashboard når data endres
  useEffect(() => {
    if (!activeWorkspace?.workspaceId) {
      return; // Ikke gjør noe hvis vi ikke har en aktiv workspace
    }
    
    if (orders.length > 0) {
      // Filtrer ordrer for i dag
      const ordersForToday = orders.filter(order => {
        // Sjekk createdAt dato
        const createdDate = getDateFromTimestamp(order.createdAt);
        if (createdDate && isToday(createdDate)) {
          return true;
        }
        
        // Sjekk lastUpdated dato
        const updatedDate = getDateFromTimestamp(order.lastUpdated);
        if (updatedDate && isToday(updatedDate)) {
          return true;
        }
        
        // Sjekk alle stopp i ruten
        if (order.route && order.route.length > 0) {
          for (let i = 0; i < order.route.length; i++) {
            const stopDate = getDateFromTimestamp(order.route[i].stopDate);
            if (stopDate && isToday(stopDate)) {
              return true;
            }
          }
        }
        
        // Sjekk om status er Draft eller Scheduled (disse vises alltid)
        if (order.status === OrderStatus.Draft || order.status === OrderStatus.Scheduled) {
          return true;
        }
        
        return false;
      });
      
      setTodaysOrders(ordersForToday);
      
      // Beregn totalverdi for dagens ordrer
      /* Kommentert ut fordi vi ikke beregner totalverdi korrekt
      let total = 0;
      const processedOrderIds = new Set<string>();
      
      ordersForToday.forEach(order => {
        // Sjekk om ordren allerede er behandlet
        if (processedOrderIds.has(order.id)) {
          return;
        }
        
        // Marker ordren som behandlet
        processedOrderIds.add(order.id);
        
        let orderTotal = 0;
        
        // Sjekk om ordren inneholder containere
        const hasContainer = order.cargo && Array.isArray(order.cargo) && order.cargo.some(item => {
          return (item.productType && Array.isArray(item.productType) && item.productType.includes("container")) ||
                 (item.descriptionShort && typeof item.descriptionShort === 'string' && item.descriptionShort.toLowerCase().includes("container")) ||
                 (item.description && typeof item.description === 'string' && item.description.toLowerCase().includes("container"));
        });
        
        if (hasContainer) {
          // Hvis ordren inneholder container, sett prisen til 7000 NOK (én gang per ordre)
          orderTotal = 7000;
        } else if (order.cargo && Array.isArray(order.cargo)) {
          // Hvis ikke container, beregn sum basert på individuelle produkter
          order.cargo.forEach(item => {
            if (item && typeof item.price === 'number') {
              orderTotal += item.price * (item.quantity || 1);
            }
          });
        }
        
        // Sjekk om det finnes en totalPrice-egenskap
        if (typeof (order as any).totalPrice === 'number' && (order as any).totalPrice > 0) {
          orderTotal = Math.max(orderTotal, (order as any).totalPrice);
        }
        
        // Sjekk om det finnes en pricing-egenskap
        if ((order as any).pricing && typeof (order as any).pricing.total === 'number') {
          orderTotal = Math.max(orderTotal, (order as any).pricing.total);
        }
        
        total += orderTotal;
      });
      
      setTotalOrderValue(total);
      */
      
      // Setter totalOrderValue til 0 siden funksjonaliteten er kommentert ut
      setTotalOrderValue(0);
      
      // Beregn statusfordeling
      const statusCount: Record<string, number> = {};
      ordersForToday.forEach(order => {
        const status = order.status || "Unknown";
        statusCount[status] = (statusCount[status] || 0) + 1;
      });
      setStatusCounts(statusCount);
      
      // Beregn fullføringsgrad
      const totalOrders = ordersForToday.length;
      const completedOrders = statusCount[OrderStatus.Completed] || 0;
      const percentage = totalOrders > 0 ? Math.round((completedOrders / totalOrders) * 100) : 0;
      setCompletionPercentage(percentage);
      
      // Finn aktive sjåfører
      const driversMap = new Map();
      ordersForToday.forEach(order => {
        if (order.driverId) {
          if (!driversMap.has(order.driverId)) {
            // Finn sjåførobjektet basert på driverId
            const driver = drivers.find(d => d.email === order.driverId);
            const driverName = driver 
              ? `${driver.firstName} ${driver.lastName}` 
              : order.driverId.substring(0, 8);
            
            driversMap.set(order.driverId, {
              id: order.driverId,
              name: driverName,
              orderCount: 0,
              orders: new Set(),
              hasInProgressOrders: false,
              hasCompletedOrders: false,
              hasOtherOrders: false
            });
          }
          
          const driverInfo = driversMap.get(order.driverId);
          driverInfo.orders.add(order.id);
          driverInfo.orderCount = driverInfo.orders.size;
          
          // Sjekk ordrestatus
          if (order.status === OrderStatus.InProgress) {
            driverInfo.hasInProgressOrders = true;
          } else if (order.status === OrderStatus.Completed) {
            driverInfo.hasCompletedOrders = true;
          } else {
            driverInfo.hasOtherOrders = true;
          }
        }
      });
      
      // Sorter sjåførene etter status: Aktive først, deretter ikke startet, og til slutt ferdig
      const sortedDrivers = Array.from(driversMap.values()).sort((a, b) => {
        // Prioritet 1: Aktive sjåfører (hasInProgressOrders)
        if (a.hasInProgressOrders && !b.hasInProgressOrders) return -1;
        if (!a.hasInProgressOrders && b.hasInProgressOrders) return 1;
        
        // Prioritet 2: Ikke startet (hasOtherOrders eller verken hasInProgressOrders eller hasCompletedOrders)
        const aNotStarted = a.hasOtherOrders || (!a.hasInProgressOrders && !a.hasCompletedOrders);
        const bNotStarted = b.hasOtherOrders || (!b.hasInProgressOrders && !b.hasCompletedOrders);
        if (aNotStarted && !bNotStarted) return -1;
        if (!aNotStarted && bNotStarted) return 1;
        
        // Prioritet 3: Ferdig (hasCompletedOrders og ikke hasInProgressOrders og ikke hasOtherOrders)
        // Disse vil automatisk havne sist
        
        // Ved lik status, sorter etter navn
        return a.name.localeCompare(b.name);
      });
      
      setActiveDrivers(sortedDrivers);
    }
  }, [orders, activeWorkspace, drivers, getDateFromTimestamp, isToday]);
  
  // Forbered data for pie chart med memoization
  const chartData = useMemo(() => {
    // Opprett canvas-element for å lage gradienter
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    
    // Sjekk om dark mode er aktivert
    const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    
    // Definer gradienter hvis context er tilgjengelig
    let gradients = [];
    if (ctx) {
      if (isDarkMode) {
        // Mørkere gradienter for dark mode
        // Gradient 1: Mørkere lilla/blå
        const blueGradient = ctx.createLinearGradient(0, 0, 200, 0);
        blueGradient.addColorStop(0, '#7214FF');
        blueGradient.addColorStop(1, '#9B7AD6');
        
        // Gradient 2: Mørkere grønn
        const greenGradient = ctx.createLinearGradient(0, 0, 200, 0);
        greenGradient.addColorStop(0, '#10B981');
        greenGradient.addColorStop(1, '#7ABFB3');
        
        // Gradient 3: Mørkere rosa
        const pinkGradient = ctx.createLinearGradient(0, 0, 200, 0);
        pinkGradient.addColorStop(0, '#EC4899');
        pinkGradient.addColorStop(1, '#D68AA0');
        
        // Gradient 4: Mørkere gul/oransje
        const yellowGradient = ctx.createLinearGradient(0, 0, 200, 0);
        yellowGradient.addColorStop(0, '#F59E0B');
        yellowGradient.addColorStop(1, '#D6C287');
        
        // Gradient 5: Mørkere rød
        const redGradient = ctx.createLinearGradient(0, 0, 200, 0);
        redGradient.addColorStop(0, '#EF4444');
        redGradient.addColorStop(1, '#F87171');
        
        gradients = [blueGradient, greenGradient, pinkGradient, yellowGradient, redGradient];
      } else {
        // Lysere gradienter for light mode
        // Gradient 1: Lilla/blå
        const blueGradient = ctx.createLinearGradient(0, 0, 200, 0);
        blueGradient.addColorStop(0, '#8B5CF6');
        blueGradient.addColorStop(1, '#C7A1FF');
        
        // Gradient 2: Grønn
        const greenGradient = ctx.createLinearGradient(0, 0, 200, 0);
        greenGradient.addColorStop(0, '#10B981');
        greenGradient.addColorStop(1, '#9DDFCF');
        
        // Gradient 3: Rosa
        const pinkGradient = ctx.createLinearGradient(0, 0, 200, 0);
        pinkGradient.addColorStop(0, '#EC4899');
        pinkGradient.addColorStop(1, '#FFACC2');
        
        // Gradient 4: Gul/oransje
        const yellowGradient = ctx.createLinearGradient(0, 0, 200, 0);
        yellowGradient.addColorStop(0, '#F59E0B');
        yellowGradient.addColorStop(1, '#FEE5A7');
        
        // Gradient 5: Rød
        const redGradient = ctx.createLinearGradient(0, 0, 200, 0);
        redGradient.addColorStop(0, '#EF4444');
        redGradient.addColorStop(1, '#FCA5A5');
        
        gradients = [blueGradient, greenGradient, pinkGradient, yellowGradient, redGradient];
      }
    } else {
      // Fallback til faste farger hvis canvas ikke er tilgjengelig
      if (isDarkMode) {
        // Mørkere farger for dark mode
        gradients = [
          '#7214FF', // Lilla
          '#10B981', // Grønn
          '#EC4899', // Rosa
          '#F59E0B', // Gul/oransje
          '#EF4444', // Rød
        ];
      } else {
        // Lysere farger for light mode
        gradients = [
          '#8B5CF6', // Lilla
          '#10B981', // Grønn
          '#EC4899', // Rosa
          '#F59E0B', // Gul/oransje
          '#EF4444', // Rød
        ];
      }
    }
    
    // Definer kantfarger basert på dark mode
    const borderColors = isDarkMode ? [
      '#6D28D9', // Lilla kant
      '#059669', // Grønn kant
      '#DB2777', // Rosa kant
      '#D97706', // Gul/oransje kant
      '#DC2626', // Rød kant
    ] : [
      '#7C3AED', // Lilla kant
      '#059669', // Grønn kant
      '#DB2777', // Rosa kant
      '#D97706', // Gul/oransje kant
      '#DC2626', // Rød kant
    ];
    
    return {
      labels: Object.keys(statusCounts).map(status => {
        // Oversett statusene korrekt
        if (status === OrderStatus.Draft) return t("stats:orderStatus.draft") || "Ikke planlagt";
        if (status === OrderStatus.Scheduled) return t("stats:orderStatus.scheduled") || "Ikke startet";
        if (status === OrderStatus.InProgress) return t("stats:orderStatus.in_progress") || "Under levering";
        return t(`stats:orderStatus.${status.toLowerCase()}`) || status;
      }),
      datasets: [
        {
          data: Object.values(statusCounts),
          backgroundColor: gradients,
          borderColor: borderColors,
          borderWidth: 1,
        },
      ],
    };
  }, [statusCounts, t]);
  
  // Oversettelser med memoization
  const translations = useMemo(() => ({
    completionProgress: t("stats:dashboard.completion_progress") || "Fremdrift på dagens leveranser",
    ordersCompleted: t("stats:dashboard.orders_completed") || "fullførte ordrer",
    orderStatusDistribution: t("stats:order_status_distribution") || "Fordeling av ordrestatus",
    totalOrderValue: t("stats:total_order_value") || "Total ordreverdi",
    forToday: t("stats:for_today") || "for i dag",
    activeDrivers: t("stats:dashboard.active_drivers") || "Aktive sjåfører",
    active: t("stats:dashboard.active") || "Aktiv",
    finished: t("stats:dashboard.finished") || "Ferdig",
    notStarted: t("stats:dashboard.not_started") || "Ikke startet",
    orders: t("stats:orders") || "ordrer",
    noOrdersToday: "Ingen ordrer i dag",
    noActiveDrivers: "Ingen aktive sjåfører",
    inProgress: t("stats:orderStatus.in_progress") || t("stats:orders.in_progress") || "Under levering",
    pending: t("stats:pending") || t("stats:orders.pending") || "Ventende",
    completed: t("stats:orderStatus.completed") || t("stats:order_status.completed") || "Fullført",
    cancelled: t("stats:orderStatus.cancelled") || t("stats:order_status.cancelled") || "Kansellert"
  }), [t]);
  
  // Beregn antall ordrer i hver status
  const orderStatusCounts = useMemo(() => {
    const inProgressCount = statusCounts[OrderStatus.InProgress] || 0;
    const pendingCount = (statusCounts[OrderStatus.New] || 0) + (statusCounts[OrderStatus.Scheduled] || 0);
    const completedCount = statusCounts[OrderStatus.Completed] || 0;
    const cancelledCount = statusCounts[OrderStatus.Cancelled] || 0;
    
    return {
      inProgressCount,
      pendingCount,
      completedCount,
      cancelledCount
    };
  }, [statusCounts]);
  
  // Vis skeleton loader hvis data lastes
  if (isLoading) {
    return <SkeletonLoader />;
  }
  
  return (
    <PageLayout>
      {/* Tynn fremdriftslinje øverst - vises bare når det er ordrer */}
      {todaysOrders.length > 0 && (
        <div className="mb-4">
          <ProgressBar 
            percentage={completionPercentage} 
            hasOrders={todaysOrders.length > 0}
            completedText={translations.ordersCompleted}
          />
        </div>
      )}
      
      {/* Ny layout for GreetingsWidget og KPI-kort */}
      <div className="flex flex-col md:flex-row gap-4 mb-4">
        {/* GreetingsWidget (1/3 bredde) */}
        <div className="md:w-1/3">
          <GreetingsWidget />
        </div>
        
        {/* KPI-kort (2/3 bredde) - grid på desktop, kolonne på mobil */}
        <div className="md:w-2/3">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4">
            <KpiCard 
              title={translations.inProgress} 
              count={orderStatusCounts.inProgressCount} 
              color="text-indigo-600" 
              onClick={() => navigate("/orders?status=InProgress")}
            />
            <KpiCard 
              title={translations.pending} 
              count={orderStatusCounts.pendingCount} 
              color="text-yellow-500" 
              onClick={() => navigate("/orders?status=New,Scheduled")}
            />
            <KpiCard 
              title={translations.completed} 
              count={orderStatusCounts.completedCount} 
              color="text-green-500" 
              onClick={() => navigate("/orders?status=Completed")}
            />
            <KpiCard 
              title={translations.cancelled} 
              count={orderStatusCounts.cancelledCount} 
              color="text-red-500" 
              onClick={() => navigate("/orders?status=Cancelled")}
            />
          </div>
        </div>
      </div>
      
      {/* KPI-seksjonen */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        <Card className="dark:bg-[#0b0f26]">
          <h5 className="text-lg font-bold tracking-tight text-gray-900 dark:text-white">
            {translations.orderStatusDistribution}
          </h5>
          <div className="h-64">
            {Object.keys(statusCounts).length > 0 ? (
              <Pie 
                data={chartData} 
                options={{ 
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      labels: {
                        color: '#6B7280'
                      }
                    }
                  }
                }} 
              />
            ) : (
              <EmptyState 
                icon={<ChartIcon />} 
                message={translations.noOrdersToday} 
              />
            )}
          </div>
        </Card>
        
        {/* Kommentert ut fordi vi ikke beregner totalverdi korrekt
        <Card className="dark:bg-[#0b0f26]">
          <h5 className="text-lg font-bold tracking-tight text-gray-900 dark:text-white">
            {translations.totalOrderValue}
          </h5>
          <div className="flex items-center justify-center h-64">
            <div className="text-center">
              <IncomeIcon />
              <span className={`text-4xl font-bold ${totalOrderValue === 0 ? 'text-gray-400 dark:text-gray-500' : 'text-gray-900 dark:text-white'} block`}>
                {totalOrderValue.toLocaleString()} NOK
              </span>
              <p className="text-gray-500 dark:text-gray-400 mt-2">
                {translations.forToday}
              </p>
            </div>
          </div>
        </Card>
        */}
        
        <Card className="dark:bg-[#0b0f26]">
          <h5 className="text-lg font-bold tracking-tight text-gray-900 dark:text-white mb-4">
            {translations.activeDrivers}
          </h5>
          <div className="h-64 overflow-auto">
            {activeDrivers.length > 0 ? (
              <DriverList 
                drivers={activeDrivers} 
                activeText={translations.active} 
                finishedText={translations.finished} 
                notStartedText={translations.notStarted} 
                ordersText={translations.orders} 
              />
            ) : (
              <EmptyState 
                icon={<DriversIcon />} 
                message={translations.noActiveDrivers} 
              />
            )}
          </div>
        </Card>
      </div>
    </PageLayout>
  );
};

export default RealtimeDashboard; 